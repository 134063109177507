import { useEffect } from "react";

export function useScript(url, useAsync, javascriptLoadedTimes) {
  useEffect(
    (useAsync) => {
      const script = document.createElement("script");

      script.src = url;
      script.async = useAsync || false;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    },
    [url]
  );
}
