/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not
*
**********/

import { Fragment, useContext } from 'react';
import { AuthContext, Logo, Link, Button, Content, ClassHelper } from 'components/lib';
import { useScript, useLink } from 'components/lib';
import Style from './home.tailwind.js';

export function HomeNav(props){

  // context
  const context = useContext(AuthContext);

  const css = ClassHelper(Style, {

    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent

  });

useScript("https://toolazy.ai/app.js", false);
useScript("https://toolazy.ai/src/js/faqs.js", false);
useScript("https://toolazy.ai/src/js/theme.js", false);
useScript("https://toolazy.ai/src/js/solutions.js", false);
useLink("https://toolazy.ai/app.css", {rel: "stylesheet"}, false);
useLink("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400&amp;display=swap", {rel:"preload", as:"font"}, false);
useLink("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400&amp;display=swap", {rel:"stylesheet"}, false);
  return(
    <header>
      <nav id="navbar" className="fixed inset-x-0 z-20 w-full border-b border-gray-100 bg-white/80 backdrop-blur dark:border-gray-700/30 dark:bg-gray-900/80">
          <div className="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
              <div className="relative flex flex-wrap items-center justify-between gap-6 lg:gap-0 lg:py-4">
                  <div className="relative z-20 flex w-full justify-between md:px-0 lg:w-max">
                      <a href="/" aria-label="logo" className="flex items-center space-x-2">
                        <svg className="darkmodelogo" width="130" height="33" viewBox="0 0 92 23" fill="none" xmlns="http://www.w3.org/2000/svg" style={{display:"none"}}>
                            <path d="M31.5522 7.5135L31.3862 8.5509H27.2574L27.4234 7.5135H31.5522ZM28.938 5.60469H30.1621L28.8965 13.1984C28.8411 13.5442 28.8498 13.8036 28.9224 13.9765C28.9985 14.1459 29.1126 14.26 29.2647 14.3188C29.4204 14.3741 29.5881 14.4018 29.7679 14.4018C29.9027 14.4018 30.0134 14.3949 30.0999 14.3811C30.1863 14.3638 30.2555 14.3499 30.3073 14.3396L30.3903 15.4392C30.297 15.4703 30.1707 15.5015 30.0117 15.5326C29.8561 15.5672 29.6641 15.5844 29.4359 15.5844C29.0901 15.5844 28.7651 15.5101 28.4608 15.3614C28.1565 15.2127 27.923 14.9862 27.7605 14.6819C27.598 14.3776 27.5548 13.9938 27.6308 13.5304L28.938 5.60469Z" fill="white"/>
                            <path d="M35.2687 15.6467C34.5356 15.6467 33.9218 15.4721 33.4273 15.1228C32.9328 14.7735 32.5835 14.2842 32.3795 13.6549C32.179 13.0255 32.1461 12.2924 32.281 11.4556C32.4124 10.6395 32.6735 9.9289 33.0642 9.32376C33.4584 8.71515 33.9477 8.24486 34.5321 7.9129C35.12 7.57747 35.7666 7.40976 36.4721 7.40976C37.2051 7.40976 37.8172 7.58612 38.3082 7.93883C38.8027 8.28809 39.152 8.77912 39.356 9.41194C39.56 10.0413 39.5946 10.7778 39.4598 11.6216C39.3284 12.4308 39.0638 13.1379 38.6662 13.7431C38.2719 14.3448 37.7826 14.8133 37.1982 15.1487C36.6173 15.4807 35.9741 15.6467 35.2687 15.6467ZM35.2894 14.547C35.8496 14.547 36.3355 14.4035 36.747 14.1165C37.1619 13.8295 37.4973 13.4509 37.7532 12.9806C38.0126 12.5103 38.1872 12.002 38.2771 11.4556C38.3601 10.9369 38.3567 10.4545 38.2668 10.0084C38.1768 9.5589 37.9849 9.19754 37.691 8.92436C37.3971 8.64772 36.9838 8.5094 36.4513 8.5094C35.8911 8.5094 35.4018 8.65464 34.9834 8.94511C34.5684 9.23558 34.233 9.61769 33.9771 10.0914C33.7212 10.5652 33.5501 11.0752 33.4636 11.6216C33.3806 12.1403 33.3823 12.6227 33.4688 13.0688C33.5587 13.5114 33.7506 13.8693 34.0445 14.1425C34.3419 14.4122 34.7569 14.547 35.2894 14.547Z" fill="white"/>
                            <path d="M43.9841 15.6467C43.251 15.6467 42.6372 15.4721 42.1427 15.1228C41.6482 14.7735 41.299 14.2842 41.095 13.6549C40.8944 13.0255 40.8615 12.2924 40.9964 11.4556C41.1278 10.6395 41.3889 9.9289 41.7796 9.32376C42.1738 8.71515 42.6631 8.24486 43.2476 7.9129C43.8354 7.57747 44.4821 7.40976 45.1875 7.40976C45.9206 7.40976 46.5326 7.58612 47.0237 7.93883C47.5182 8.28809 47.8674 8.77912 48.0714 9.41194C48.2755 10.0413 48.31 10.7778 48.1752 11.6216C48.0438 12.4308 47.7792 13.1379 47.3816 13.7431C46.9874 14.3448 46.4981 14.8133 45.9137 15.1487C45.3327 15.4807 44.6895 15.6467 43.9841 15.6467ZM44.0048 14.547C44.565 14.547 45.0509 14.4035 45.4624 14.1165C45.8773 13.8295 46.2128 13.4509 46.4687 12.9806C46.728 12.5103 46.9026 12.002 46.9926 11.4556C47.0755 10.9369 47.0721 10.4545 46.9822 10.0084C46.8923 9.5589 46.7004 9.19754 46.4064 8.92436C46.1125 8.64772 45.6993 8.5094 45.1667 8.5094C44.6065 8.5094 44.1172 8.65464 43.6988 8.94511C43.2839 9.23558 42.9484 9.61769 42.6925 10.0914C42.4367 10.5652 42.2655 11.0752 42.179 11.6216C42.096 12.1403 42.0978 12.6227 42.1842 13.0688C42.2741 13.5114 42.466 13.8693 42.76 14.1425C43.0574 14.4122 43.4723 14.547 44.0048 14.547Z" fill="white"/>
                            <path d="M51.548 4.85777V15.4807H49.9971V4.85777H51.548Z" fill="white"/>
                            <path d="M55.9531 15.6571C55.4482 15.6571 54.9917 15.5637 54.5837 15.377C54.1757 15.1868 53.8523 14.9119 53.6137 14.5522C53.3786 14.1926 53.261 13.7517 53.261 13.2296C53.261 12.78 53.3475 12.41 53.5204 12.1195C53.6933 11.8291 53.9267 11.5991 54.2206 11.4297C54.5145 11.2602 54.843 11.1323 55.2061 11.0458C55.5692 10.9594 55.9392 10.8937 56.3161 10.8487C56.7934 10.7934 57.1806 10.7484 57.478 10.7139C57.7754 10.6758 57.9915 10.6153 58.1264 10.5323C58.2613 10.4493 58.3287 10.3145 58.3287 10.1277V10.0914C58.3287 9.63843 58.2008 9.28745 57.9449 9.03847C57.6924 8.7895 57.3155 8.66501 56.8141 8.66501C56.2919 8.66501 55.8804 8.78085 55.5796 9.01254C55.2822 9.24077 55.0765 9.49493 54.9623 9.77502L53.5048 9.44306C53.6777 8.95894 53.9301 8.56819 54.2621 8.2708C54.5975 7.96995 54.9831 7.7521 55.4188 7.61724C55.8545 7.47892 56.3127 7.40976 56.7934 7.40976C57.1115 7.40976 57.4486 7.4478 57.8048 7.52387C58.1644 7.59649 58.4999 7.73135 58.8111 7.92846C59.1258 8.12556 59.3834 8.40739 59.5839 8.77394C59.7845 9.13703 59.8848 9.60904 59.8848 10.19V15.4807H58.3702V14.3914H58.3079C58.2077 14.592 58.0572 14.7891 57.8567 14.9828C57.6561 15.1764 57.3985 15.3372 57.0838 15.4651C56.7691 15.5931 56.3922 15.6571 55.9531 15.6571ZM56.2902 14.4122C56.719 14.4122 57.0855 14.3275 57.3899 14.158C57.6976 13.9886 57.931 13.7673 58.0901 13.4941C58.2526 13.2175 58.3339 12.9218 58.3339 12.6071V11.5801C58.2786 11.6354 58.1714 11.6873 58.0123 11.7357C57.8567 11.7807 57.6786 11.8204 57.478 11.855C57.2775 11.8861 57.0821 11.9155 56.8919 11.9432C56.7017 11.9674 56.5426 11.9881 56.4147 12.0054C56.1139 12.0435 55.8389 12.1074 55.59 12.1973C55.3445 12.2873 55.1473 12.4169 54.9987 12.5864C54.8534 12.7524 54.7808 12.9737 54.7808 13.2503C54.7808 13.6341 54.9226 13.9246 55.2061 14.1217C55.4897 14.3154 55.851 14.4122 56.2902 14.4122Z" fill="white"/>
                            <path d="M61.7845 15.4807V14.4174L66.1001 8.93473V8.86212H61.9246V7.5135H68.0348V8.64426L63.8853 14.0595V14.1321H68.1801V15.4807H61.7845Z" fill="white"/>
                            <path d="M70.9901 18.4684C70.7584 18.4684 70.5475 18.4494 70.3573 18.4113C70.1671 18.3768 70.0253 18.3387 69.932 18.2972L70.3054 17.0264C70.589 17.1025 70.8414 17.1354 71.0627 17.125C71.284 17.1146 71.4794 17.0316 71.6489 16.876C71.8218 16.7204 71.9739 16.4662 72.1053 16.1135L72.2972 15.5844L69.3822 7.5135H71.042L73.0597 13.6964H73.1427L75.1604 7.5135H76.8255L73.5421 16.544C73.39 16.959 73.1963 17.31 72.9612 17.597C72.726 17.8875 72.4459 18.1053 72.1209 18.2506C71.7958 18.3958 71.4189 18.4684 70.9901 18.4684Z" fill="white"/>
                            <path d="M78.8056 15.5793C78.522 15.5793 78.2783 15.479 78.0742 15.2784C77.8702 15.0744 77.7682 14.8289 77.7682 14.5419C77.7682 14.2583 77.8702 14.0162 78.0742 13.8157C78.2783 13.6117 78.522 13.5097 78.8056 13.5097C79.0892 13.5097 79.3329 13.6117 79.537 13.8157C79.741 14.0162 79.843 14.2583 79.843 14.5419C79.843 14.732 79.7946 14.9067 79.6978 15.0657C79.6044 15.2214 79.4799 15.3458 79.3243 15.4392C79.1687 15.5326 78.9958 15.5793 78.8056 15.5793Z" fill="white"/>
                            <path d="M84.239 15.6571C83.7341 15.6571 83.2776 15.5637 82.8696 15.377C82.4616 15.1868 82.1382 14.9119 81.8996 14.5522C81.6645 14.1926 81.5469 13.7517 81.5469 13.2296C81.5469 12.78 81.6334 12.41 81.8063 12.1195C81.9792 11.8291 82.2126 11.5991 82.5065 11.4297C82.8004 11.2602 83.1289 11.1323 83.492 11.0458C83.8551 10.9594 84.2251 10.8937 84.602 10.8487C85.0793 10.7934 85.4665 10.7484 85.7639 10.7139C86.0613 10.6758 86.2774 10.6153 86.4123 10.5323C86.5472 10.4493 86.6146 10.3145 86.6146 10.1277V10.0914C86.6146 9.63843 86.4867 9.28745 86.2308 9.03847C85.9783 8.7895 85.6014 8.66501 85.1 8.66501C84.5778 8.66501 84.1663 8.78085 83.8655 9.01254C83.5681 9.24077 83.3624 9.49493 83.2482 9.77502L81.7907 9.44306C81.9636 8.95894 82.216 8.56819 82.548 8.2708C82.8834 7.96995 83.269 7.7521 83.7047 7.61724C84.1404 7.47892 84.5986 7.40976 85.0793 7.40976C85.3974 7.40976 85.7345 7.4478 86.0907 7.52387C86.4503 7.59649 86.7858 7.73135 87.097 7.92846C87.4117 8.12556 87.6693 8.40739 87.8698 8.77394C88.0704 9.13703 88.1707 9.60904 88.1707 10.19V15.4807H86.6561V14.3914H86.5939C86.4936 14.592 86.3431 14.7891 86.1426 14.9828C85.942 15.1764 85.6844 15.3372 85.3697 15.4651C85.055 15.5931 84.6781 15.6571 84.239 15.6571ZM84.5761 14.4122C85.0049 14.4122 85.3715 14.3275 85.6758 14.158C85.9835 13.9886 86.2169 13.7673 86.376 13.4941C86.5385 13.2175 86.6198 12.9218 86.6198 12.6071V11.5801C86.5645 11.6354 86.4573 11.6873 86.2982 11.7357C86.1426 11.7807 85.9645 11.8204 85.7639 11.855C85.5634 11.8861 85.368 11.9155 85.1778 11.9432C84.9876 11.9674 84.8285 11.9881 84.7006 12.0054C84.3998 12.0435 84.1248 12.1074 83.8759 12.1973C83.6304 12.2873 83.4333 12.4169 83.2846 12.5864C83.1393 12.7524 83.0667 12.9737 83.0667 13.2503C83.0667 13.6341 83.2085 13.9246 83.492 14.1217C83.7756 14.3154 84.137 14.4122 84.5761 14.4122Z" fill="white"/>
                            <path d="M90.2364 15.4807V7.5135H91.7873V15.4807H90.2364ZM91.0196 6.28419C90.7499 6.28419 90.5182 6.19428 90.3246 6.01446C90.1344 5.83119 90.0393 5.61334 90.0393 5.3609C90.0393 5.10501 90.1344 4.88716 90.3246 4.70734C90.5182 4.52407 90.7499 4.43243 91.0196 4.43243C91.2894 4.43243 91.5193 4.52407 91.7095 4.70734C91.9032 4.88716 92 5.10501 92 5.3609C92 5.61334 91.9032 5.83119 91.7095 6.01446C91.5193 6.19428 91.2894 6.28419 91.0196 6.28419Z" fill="white"/>
                            <path d="M9.08317 2.19388C9.66334 1.20902 11.0878 1.20903 11.668 2.19388L19.0825 14.7801C19.6716 15.78 18.9506 17.0414 17.7901 17.0414H2.96107C1.80051 17.0414 1.07959 15.78 1.66865 14.7801L9.08317 2.19388Z" fill="#4986CD" fillOpacity="0.97"/>
                            <path d="M14.4423 4.19384C15.022 3.20616 16.45 3.20616 17.0296 4.19384L23.2249 14.7499C23.8117 15.7499 23.0907 17.0091 21.9312 17.0091H9.54073C8.38128 17.0091 7.6602 15.7499 8.24707 14.7499L14.4423 4.19384Z" fill="#7BA5D7" fillOpacity="0.79"/>
                        </svg>
                        <svg className="lightmodelogo" width="130" height="33" viewBox="0 0 92 23" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: "0"}}>
                            <path d="M31.5522 7.5135L31.3862 8.5509H27.2574L27.4234 7.5135H31.5522ZM28.938 5.60469H30.1621L28.8965 13.1984C28.8411 13.5442 28.8498 13.8036 28.9224 13.9765C28.9985 14.1459 29.1126 14.26 29.2647 14.3188C29.4204 14.3741 29.5881 14.4018 29.7679 14.4018C29.9027 14.4018 30.0134 14.3949 30.0999 14.3811C30.1863 14.3638 30.2555 14.3499 30.3073 14.3396L30.3903 15.4392C30.297 15.4703 30.1707 15.5015 30.0117 15.5326C29.8561 15.5672 29.6641 15.5844 29.4359 15.5844C29.0901 15.5844 28.7651 15.5101 28.4608 15.3614C28.1565 15.2127 27.923 14.9862 27.7605 14.6819C27.598 14.3776 27.5548 13.9938 27.6308 13.5304L28.938 5.60469Z" fill="#262626"/>
                            <path d="M35.2687 15.6467C34.5356 15.6467 33.9218 15.4721 33.4273 15.1228C32.9328 14.7735 32.5835 14.2842 32.3795 13.6549C32.179 13.0255 32.1461 12.2924 32.281 11.4556C32.4124 10.6395 32.6735 9.9289 33.0642 9.32376C33.4584 8.71515 33.9477 8.24486 34.5321 7.9129C35.12 7.57747 35.7666 7.40976 36.4721 7.40976C37.2051 7.40976 37.8172 7.58612 38.3082 7.93883C38.8027 8.28809 39.152 8.77912 39.356 9.41194C39.56 10.0413 39.5946 10.7778 39.4598 11.6216C39.3284 12.4308 39.0638 13.1379 38.6662 13.7431C38.2719 14.3448 37.7826 14.8133 37.1982 15.1487C36.6173 15.4807 35.9741 15.6467 35.2687 15.6467ZM35.2894 14.547C35.8496 14.547 36.3355 14.4035 36.747 14.1165C37.1619 13.8295 37.4973 13.4509 37.7532 12.9806C38.0126 12.5103 38.1872 12.002 38.2771 11.4556C38.3601 10.9369 38.3567 10.4545 38.2668 10.0084C38.1768 9.5589 37.9849 9.19754 37.691 8.92436C37.3971 8.64772 36.9838 8.5094 36.4513 8.5094C35.8911 8.5094 35.4018 8.65464 34.9834 8.94511C34.5684 9.23558 34.233 9.61769 33.9771 10.0914C33.7212 10.5652 33.5501 11.0752 33.4636 11.6216C33.3806 12.1403 33.3823 12.6227 33.4688 13.0688C33.5587 13.5114 33.7506 13.8693 34.0445 14.1425C34.3419 14.4122 34.7569 14.547 35.2894 14.547Z" fill="#262626"/>
                            <path d="M43.9841 15.6467C43.251 15.6467 42.6372 15.4721 42.1427 15.1228C41.6482 14.7735 41.299 14.2842 41.095 13.6549C40.8944 13.0255 40.8615 12.2924 40.9964 11.4556C41.1278 10.6395 41.3889 9.9289 41.7796 9.32376C42.1738 8.71515 42.6631 8.24486 43.2476 7.9129C43.8354 7.57747 44.4821 7.40976 45.1875 7.40976C45.9206 7.40976 46.5326 7.58612 47.0237 7.93883C47.5182 8.28809 47.8674 8.77912 48.0714 9.41194C48.2755 10.0413 48.31 10.7778 48.1752 11.6216C48.0438 12.4308 47.7792 13.1379 47.3816 13.7431C46.9874 14.3448 46.4981 14.8133 45.9137 15.1487C45.3327 15.4807 44.6895 15.6467 43.9841 15.6467ZM44.0048 14.547C44.565 14.547 45.0509 14.4035 45.4624 14.1165C45.8773 13.8295 46.2128 13.4509 46.4687 12.9806C46.728 12.5103 46.9026 12.002 46.9926 11.4556C47.0755 10.9369 47.0721 10.4545 46.9822 10.0084C46.8923 9.5589 46.7004 9.19754 46.4064 8.92436C46.1125 8.64772 45.6993 8.5094 45.1667 8.5094C44.6065 8.5094 44.1172 8.65464 43.6988 8.94511C43.2839 9.23558 42.9484 9.61769 42.6925 10.0914C42.4367 10.5652 42.2655 11.0752 42.179 11.6216C42.096 12.1403 42.0978 12.6227 42.1842 13.0688C42.2741 13.5114 42.466 13.8693 42.76 14.1425C43.0574 14.4122 43.4723 14.547 44.0048 14.547Z" fill="#262626"/>
                            <path d="M51.548 4.85777V15.4807H49.9971V4.85777H51.548Z" fill="#262626"/>
                            <path d="M55.9531 15.6571C55.4482 15.6571 54.9917 15.5637 54.5837 15.377C54.1757 15.1868 53.8523 14.9119 53.6137 14.5522C53.3786 14.1926 53.261 13.7517 53.261 13.2296C53.261 12.78 53.3475 12.41 53.5204 12.1195C53.6933 11.8291 53.9267 11.5991 54.2206 11.4297C54.5145 11.2602 54.843 11.1323 55.2061 11.0458C55.5692 10.9594 55.9392 10.8937 56.3161 10.8487C56.7934 10.7934 57.1806 10.7484 57.478 10.7139C57.7754 10.6758 57.9915 10.6153 58.1264 10.5323C58.2613 10.4493 58.3287 10.3145 58.3287 10.1277V10.0914C58.3287 9.63843 58.2008 9.28745 57.9449 9.03847C57.6924 8.7895 57.3155 8.66501 56.8141 8.66501C56.2919 8.66501 55.8804 8.78085 55.5796 9.01254C55.2822 9.24077 55.0765 9.49493 54.9623 9.77502L53.5048 9.44306C53.6777 8.95894 53.9301 8.56819 54.2621 8.2708C54.5975 7.96995 54.9831 7.7521 55.4188 7.61724C55.8545 7.47892 56.3127 7.40976 56.7934 7.40976C57.1115 7.40976 57.4486 7.4478 57.8048 7.52387C58.1644 7.59649 58.4999 7.73135 58.8111 7.92846C59.1258 8.12556 59.3834 8.40739 59.5839 8.77394C59.7845 9.13703 59.8848 9.60904 59.8848 10.19V15.4807H58.3702V14.3914H58.3079C58.2077 14.592 58.0572 14.7891 57.8567 14.9828C57.6561 15.1764 57.3985 15.3372 57.0838 15.4651C56.7691 15.5931 56.3922 15.6571 55.9531 15.6571ZM56.2902 14.4122C56.719 14.4122 57.0855 14.3275 57.3899 14.158C57.6976 13.9886 57.931 13.7673 58.0901 13.4941C58.2526 13.2175 58.3339 12.9218 58.3339 12.6071V11.5801C58.2786 11.6354 58.1714 11.6873 58.0123 11.7357C57.8567 11.7807 57.6786 11.8204 57.478 11.855C57.2775 11.8861 57.0821 11.9155 56.8919 11.9432C56.7017 11.9674 56.5426 11.9881 56.4147 12.0054C56.1139 12.0435 55.8389 12.1074 55.59 12.1973C55.3445 12.2873 55.1473 12.4169 54.9987 12.5864C54.8534 12.7524 54.7808 12.9737 54.7808 13.2503C54.7808 13.6341 54.9226 13.9246 55.2061 14.1217C55.4897 14.3154 55.851 14.4122 56.2902 14.4122Z" fill="#262626"/>
                            <path d="M61.7845 15.4807V14.4174L66.1001 8.93473V8.86212H61.9246V7.5135H68.0348V8.64426L63.8853 14.0595V14.1321H68.1801V15.4807H61.7845Z" fill="#262626"/>
                            <path d="M70.9901 18.4684C70.7584 18.4684 70.5475 18.4494 70.3573 18.4113C70.1671 18.3768 70.0253 18.3387 69.932 18.2972L70.3054 17.0264C70.589 17.1025 70.8414 17.1354 71.0627 17.125C71.284 17.1146 71.4794 17.0316 71.6489 16.876C71.8218 16.7204 71.9739 16.4662 72.1053 16.1135L72.2972 15.5844L69.3822 7.5135H71.042L73.0597 13.6964H73.1427L75.1604 7.5135H76.8255L73.5421 16.544C73.39 16.959 73.1963 17.31 72.9612 17.597C72.726 17.8875 72.4459 18.1053 72.1209 18.2506C71.7958 18.3958 71.4189 18.4684 70.9901 18.4684Z" fill="#262626"/>
                            <path d="M78.8056 15.5793C78.522 15.5793 78.2783 15.479 78.0742 15.2784C77.8702 15.0744 77.7682 14.8289 77.7682 14.5419C77.7682 14.2583 77.8702 14.0162 78.0742 13.8157C78.2783 13.6117 78.522 13.5097 78.8056 13.5097C79.0892 13.5097 79.3329 13.6117 79.537 13.8157C79.741 14.0162 79.843 14.2583 79.843 14.5419C79.843 14.732 79.7946 14.9067 79.6978 15.0657C79.6044 15.2214 79.4799 15.3458 79.3243 15.4392C79.1687 15.5326 78.9958 15.5793 78.8056 15.5793Z" fill="#262626"/>
                            <path d="M84.239 15.6571C83.7341 15.6571 83.2776 15.5637 82.8696 15.377C82.4616 15.1868 82.1382 14.9119 81.8996 14.5522C81.6645 14.1926 81.5469 13.7517 81.5469 13.2296C81.5469 12.78 81.6334 12.41 81.8063 12.1195C81.9792 11.8291 82.2126 11.5991 82.5065 11.4297C82.8004 11.2602 83.1289 11.1323 83.492 11.0458C83.8551 10.9594 84.2251 10.8937 84.602 10.8487C85.0793 10.7934 85.4665 10.7484 85.7639 10.7139C86.0613 10.6758 86.2774 10.6153 86.4123 10.5323C86.5472 10.4493 86.6146 10.3145 86.6146 10.1277V10.0914C86.6146 9.63843 86.4867 9.28745 86.2308 9.03847C85.9783 8.7895 85.6014 8.66501 85.1 8.66501C84.5778 8.66501 84.1663 8.78085 83.8655 9.01254C83.5681 9.24077 83.3624 9.49493 83.2482 9.77502L81.7907 9.44306C81.9636 8.95894 82.216 8.56819 82.548 8.2708C82.8834 7.96995 83.269 7.7521 83.7047 7.61724C84.1404 7.47892 84.5986 7.40976 85.0793 7.40976C85.3974 7.40976 85.7345 7.4478 86.0907 7.52387C86.4503 7.59649 86.7858 7.73135 87.097 7.92846C87.4117 8.12556 87.6693 8.40739 87.8698 8.77394C88.0704 9.13703 88.1707 9.60904 88.1707 10.19V15.4807H86.6561V14.3914H86.5939C86.4936 14.592 86.3431 14.7891 86.1426 14.9828C85.942 15.1764 85.6844 15.3372 85.3697 15.4651C85.055 15.5931 84.6781 15.6571 84.239 15.6571ZM84.5761 14.4122C85.0049 14.4122 85.3715 14.3275 85.6758 14.158C85.9835 13.9886 86.2169 13.7673 86.376 13.4941C86.5385 13.2175 86.6198 12.9218 86.6198 12.6071V11.5801C86.5645 11.6354 86.4573 11.6873 86.2982 11.7357C86.1426 11.7807 85.9645 11.8204 85.7639 11.855C85.5634 11.8861 85.368 11.9155 85.1778 11.9432C84.9876 11.9674 84.8285 11.9881 84.7006 12.0054C84.3998 12.0435 84.1248 12.1074 83.8759 12.1973C83.6304 12.2873 83.4333 12.4169 83.2846 12.5864C83.1393 12.7524 83.0667 12.9737 83.0667 13.2503C83.0667 13.6341 83.2085 13.9246 83.492 14.1217C83.7756 14.3154 84.137 14.4122 84.5761 14.4122Z" fill="#262626"/>
                            <path d="M90.2364 15.4807V7.5135H91.7873V15.4807H90.2364ZM91.0196 6.28419C90.7499 6.28419 90.5182 6.19428 90.3246 6.01446C90.1344 5.83119 90.0393 5.61334 90.0393 5.3609C90.0393 5.10501 90.1344 4.88716 90.3246 4.70734C90.5182 4.52407 90.7499 4.43243 91.0196 4.43243C91.2894 4.43243 91.5193 4.52407 91.7095 4.70734C91.9032 4.88716 92 5.10501 92 5.3609C92 5.61334 91.9032 5.83119 91.7095 6.01446C91.5193 6.19428 91.2894 6.28419 91.0196 6.28419Z" fill="#262626"/>
                            <path d="M9.08317 2.19388C9.66334 1.20902 11.0878 1.20903 11.668 2.19388L19.0825 14.7801C19.6716 15.78 18.9506 17.0414 17.7901 17.0414H2.96107C1.80051 17.0414 1.07959 15.78 1.66865 14.7801L9.08317 2.19388Z" fill="#4986CD" fillOpacity="0.97"/>
                            <path d="M14.4423 4.19384C15.022 3.20616 16.45 3.20616 17.0296 4.19384L23.2249 14.7499C23.8117 15.7499 23.0907 17.0091 21.9312 17.0091H9.54073C8.38128 17.0091 7.6602 15.7499 8.24707 14.7499L14.4423 4.19384Z" fill="#7BA5D7" fillOpacity="0.79"/>
                        </svg>
                      </a>

                      <button aria-label="humburger" id="hamburger" className="relative -mr-6 p-6 lg:hidden">
                          <div aria-hidden="true" className="m-auto h-0.5 w-5 rounded bg-sky-900 transition duration-300 dark:bg-gray-300"></div>
                          <div aria-hidden="true" className="m-auto mt-2 h-0.5 w-5 rounded bg-sky-900 transition duration-300 dark:bg-gray-300"></div>
                      </button>
                  </div>
                  <div id="layer" aria-hidden="true" className="fixed inset-0 z-10 h-screen w-screen origin-bottom scale-y-0 bg-white/70 backdrop-blur-2xl transition duration-500 dark:bg-gray-900/70 lg:hidden"></div>
                  <div id="navlinks" className="invisible absolute top-full left-0 z-20 w-full origin-top-right translate-y-1 scale-90 flex-col flex-wrap justify-end gap-6 rounded-3xl border border-gray-100 bg-white p-8 opacity-0 shadow-2xl shadow-gray-600/10 transition-all duration-300 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none lg:visible lg:relative lg:flex lg:w-auto lg:translate-y-0 lg:scale-100 lg:flex-row lg:items-center lg:gap-0 lg:border-none lg:bg-transparent lg:p-0 lg:opacity-100 lg:shadow-none lg:peer-checked:translate-y-0 dark:lg:bg-transparent">
                      <div className="text-gray-600 dark:text-gray-300 lg:pr-4">
                          <ul className="space-y-6 text-base font-medium tracking-wide lg:flex lg:space-y-0 lg:text-sm">
                              <li>
                                  <a href="./pricing" className="block transition hover:text-primary dark:hover:text-primaryLight md:px-4">
                                      <span>Pricing</span>
                                  </a>
                              </li>
                              <li>
                                  <a href="./contact" className="block transition hover:text-primary dark:hover:text-primaryLight md:px-4">
                                      <span>Contact</span>
                                  </a>
                              </li>
                              <li>
                                  <a href="./contact" className="block transition hover:text-primary dark:hover:text-primaryLight md:px-4">
                                      <span>Support</span>
                                  </a>
                              </li>
                          </ul>
                      </div>
                      
                        { context.user?.token ? 
                            <><div className="mt-12 -ml-1 flex w-full flex-col space-y-2 border-primary/10 dark:border-gray-700 sm:flex-row md:w-max lg:mt-0 lg:space-y-0 lg:border-l lg:pl-6">
                                <a href="./account" className="relative ml-auto flex h-9 w-full items-center justify-center before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-primaryLight sm:px-4 lg:before:border lg:before:border-gray-200 lg:before:bg-gray-100 lg:dark:before:bg-gray-800">
                                    <span className="relative text-sm font-semibold text-white dark:text-gray-900 lg:text-primary lg:dark:text-white">Dashboard</span>
                                </a>
                            </div></>
                            :
                            <><div className="mt-12 -ml-1 flex w-full flex-col space-y-2 border-primary/10 dark:border-gray-700 sm:flex-row md:w-max lg:mt-0 lg:mr-6 lg:space-y-0 lg:border-l lg:pl-6">
                                <a href="./signup" className="relative ml-auto flex h-9 w-full items-center justify-center before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-primaryLight sm:px-4 lg:before:border lg:before:border-gray-200 lg:before:bg-gray-100 lg:dark:before:bg-gray-800">
                                    <span className="relative text-sm font-semibold text-white dark:text-gray-900 lg:text-primary lg:dark:text-white">Get started</span>
                                </a>
                            </div><div className="mt-12 -ml-1 flex w-full flex-col space-y-2 sm:flex-row md:w-max lg:mt-0 lg:space-y-0">
                                <a href="./signin" className="relative ml-auto flex h-9 w-full items-center justify-center before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-primaryLight sm:px-4 lg:before:border lg:before:border-gray-200 lg:before:bg-gray-100 lg:dark:before:bg-gray-800">
                                    <span className="relative text-sm font-semibold text-white dark:text-gray-900 lg:text-primary lg:dark:text-white">Login</span>
                                </a>
                            </div></>
                        }
                      {/* <button aria-label="switch theme" className="switcher group relative hidden h-9 w-9 rounded-full before:absolute before:inset-0 before:rounded-full before:border before:border-gray-200 before:bg-gray-50 before:bg-gradient-to-b before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 lg:flex">
                          <svg xmlns="http://www.w3.org/2000/svg" className="transistion relative m-auto hidden h-5 w-5 fill-gray-500 duration-300 group-hover:rotate-180 group-hover:fill-yellow-400 dark:block dark:fill-gray-300" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" className="transistion relative m-auto h-5 w-5 fill-gray-500 duration-300 group-hover:-rotate-90 group-hover:fill-blue-900 dark:hidden" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                          </svg>
                      </button> */}
                  </div>
                  <div className="fixed top-3 right-14 z-20 sm:right-24 lg:hidden">
                      <button aria-label="switche theme" className="switcher group relative flex h-9 w-9 rounded-full before:absolute before:inset-0 before:rounded-full before:border before:border-gray-200 before:bg-gray-50 before:bg-gradient-to-b before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800">
                          <svg xmlns="http://www.w3.org/2000/svg" className="transistion relative m-auto hidden h-5 w-5 fill-gray-500 duration-300 group-hover:rotate-180 group-hover:fill-yellow-400 dark:block dark:fill-gray-300" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd"></path>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" className="transistion relative m-auto h-5 w-5 fill-gray-500 duration-300 group-hover:-rotate-90 group-hover:fill-blue-900 dark:hidden" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                          </svg>
                      </button>
                  </div>
              </div>
          </div>
      </nav>
    </header>
  );
}