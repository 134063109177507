import { useEffect } from "react";

export function useLink(url, props, useAsync) {
  useEffect(
    (useAsync) => {
      const link = document.createElement("link");

      link.href = url;
      //loop thru props
      for (const [key, value] of Object.entries(props)) {
        link[key] = value;
      }
      link.async = useAsync || false;

      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    },
    [url]
  );
}
