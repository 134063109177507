// import { Fragment, useState } from 'react';
import { Fragment } from "react";
// import { Loader, useAPI } from 'components/lib';
import { useAPI } from "components/lib";

export function Summarize(props) {
  // const [state, setState] = useState({});
  const data = useAPI("/api/summarize");

  if (!data) return false;

  return <Fragment>{/* render view data */}</Fragment>;
}
