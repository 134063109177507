/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useContext } from "react";
import { AuthContext, Animate, Grid, Card, Icon, Link, useAPI, Loader } from "components/lib";

export function Account(props) {
  const context = useContext(AuthContext);
  const user = useAPI("/api/user");
  const iconSize = 20;
  const token = useAPI("/api/auth/token");
  const userGetToken = useAPI("/api/user/getToken");

  console.log("HELLO, ACCOUNT PAGEAEASD");
  if (user.loading) return <Loader />;

  let userToken;
  if (token.loading === false && token.data) {
    console.log("now loaded token", token);
    console.log("now loaded user", user);
    if (token.data.token[0]) {
      userToken = token.data.token[0].access;
      window.userToken = userToken;
    } else {
      console.log("no token in user??", userGetToken);
      if (userGetToken.loading === false && userGetToken.data) {
        console.log("now loaded userGetToken", userGetToken);
        if (userGetToken.data[0]) {
          userToken = userGetToken.data[0].access;
          window.userToken = userToken;
        }
      }
    }
  }

  let totalTimeSavedString = "";
  if (user?.data?.total_time_saved) {
    let totalTimeSavedSeconds = user.data.total_time_saved;
    //convert to minutes
    let totalTimeSavedMinutes = Math.floor(totalTimeSavedSeconds / 60);
    //convert to hours
    let totalTimeSavedHours = Math.floor(totalTimeSavedMinutes / 60);
    //convert to days
    let totalTimeSavedDays = Math.floor(totalTimeSavedHours / 24);

    if (totalTimeSavedDays >= 1) {
      if (totalTimeSavedDays == 1) {
        totalTimeSavedString += totalTimeSavedDays + " Day ";
      } else {
        totalTimeSavedString += totalTimeSavedDays + " Days ";
      }
      totalTimeSavedHours = totalTimeSavedHours % 24;
    }
    if (totalTimeSavedHours >= 1) {
      if (totalTimeSavedHours == 1) {
        totalTimeSavedString += totalTimeSavedHours + " Hour ";
      } else {
        totalTimeSavedString += totalTimeSavedHours + " Hours ";
      }
      totalTimeSavedMinutes = totalTimeSavedMinutes % 60;
    }
    if (totalTimeSavedDays < 1 && totalTimeSavedMinutes >= 1) {
      //only show if less than 1 day
      if (totalTimeSavedMinutes == 1) {
        totalTimeSavedString += totalTimeSavedMinutes + " Minute ";
      } else {
        totalTimeSavedString += totalTimeSavedMinutes + " Minutes ";
      }
      totalTimeSavedSeconds = totalTimeSavedSeconds % 60;
    }
    // if (totalTimeSavedHours < 1 && totalTimeSavedSeconds >= 1) {
    //   //only show if less than 1 hour
    //   if (totalTimeSavedSeconds == 1) {
    //     totalTimeSavedString += totalTimeSavedSeconds + " Second";
    //   } else {
    //     totalTimeSavedString += totalTimeSavedSeconds + " Seconds";
    //   }
    // }
    totalTimeSavedString = totalTimeSavedString.trim();
  }

  return (
    <Animate>
      {totalTimeSavedString && (
        <Card>
          <h1 className="text-2xl font-bold">Total Time Saved</h1>
          <h2 className="text-4xl font-bold">{totalTimeSavedString}</h2>
        </Card>
      )}
      <Grid cols="4">
        <Card>
          <Icon image="user" size={iconSize} />
          <h2 className={Style.subtitle}>Profile</h2>
          <div>Update your profile information</div>
          <Link url="/account/profile" className={Style.link}>
            Update
          </Link>
        </Card>

        <Card>
          <Icon image="lock" size={iconSize} />
          <h2 className={Style.subtitle}>Password</h2>
          <div>{user?.data?.["has_password"] ? "Change your password" : "Create a password"}</div>
          <Link url="/account/password" className={Style.link}>
            {user?.data?.["has_password"] ? "Change" : "Create"}
          </Link>
        </Card>

        {false && (
          <Card>
            <Icon image="shield" size={iconSize} />
            <h2 className={Style.subtitle}>Two-Factor Authentication</h2>
            <div>Secure your account</div>
            <Link url="/account/2fa" className={Style.link}>
              {user?.data?.["2fa_enabled"] ? "Manage" : "Enable"}
            </Link>
          </Card>
        )}

        {context.permission?.owner && (
          <Card>
            <Icon image="credit-card" size={iconSize} />
            <h2 className={Style.subtitle}>Billing</h2>
            <div>Update your plan or credit card</div>
            <Link url="/account/billing" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}

        {context.permission?.developer && false && (
          <Card>
            <Icon image="settings" size={iconSize} />
            <h2 className={Style.subtitle}>API Keys</h2>
            <div>Manage your API keys</div>
            <Link url="/account/apikeys" className={Style.link}>
              Manage
            </Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="users" size={iconSize} />
            <h2 className={Style.subtitle}>Users</h2>
            <div>Invite users to your account</div>
            <Link url="/account/users" className={Style.link}>
              Add users
            </Link>
          </Card>
        )}
      </Grid>
    </Animate>
  );
}

const Style = {
  subtitle: "font-bold mt-4",
  link: "text-blue-500 font-semibold mt-3 inline-block",
};
